import Axios from "axios";

const api = Axios.create({
  baseURL: "https://api.menu.siciliano.az/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

class HttpClient {
  get(url, configs) {
    return api.get(url, configs);
  }
}

export const httpClient = new HttpClient();
