import { ChakraProvider } from "@chakra-ui/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import QRCodeGenerator from "./QRCodeGenerator";
import MenuPage from "./MenuPage";
import Bar from "./Bar";
import Dessert from "./Dessert";
import CategoryItemsPage from "./CategoryItemsPage";
import Layout from "./Layout";

function App() {
  // const menuPageURL = "https://menu.siciliano.az/";
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            {/* <Route path="/menu" element={<MenuPage />} /> */}
            <Route path="/" element={<Navigate to="/menu" />} />
            <Route path="/menu" element={<MenuPage />} />

            <Route path="/bar" element={<Bar />} />
            <Route path="/dessert" element={<Dessert />} />
            {/* <Route path="/category/:categoryName" element={< CategoryItemsPage/>} /> */}

            <Route
              path="/menu/:categoryName/:type?"
              element={<CategoryItemsPage />}
            />
            {/* <Route
              path="/menu/:categoryName"
              element={<CategoryItemsPage />}
            /> */}
            <Route path="/bar/:categoryName" element={<CategoryItemsPage />} />
            <Route
              path="/dessert/:categoryName"
              element={<CategoryItemsPage />}
            />
          </Route>
        </Routes>
      </Router>
      {/* <QRCodeGenerator url={menuPageURL} /> */}
    </ChakraProvider>
  );
}

export default App;
