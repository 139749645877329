import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "../Modal.css";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tCoffeImg from "../assets/img/TCoffe.jpg";

const CategoryItems = ({ items, categoryName }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState(null);
  const { t, i18n } = useTranslation("global");

  const handleImageClick = (item) => {
    setSelectedItem(item);
    // onOpen();
    setModal(true);
  };

  const handleTCoffee = (item) => {
    setSelectedItem(item);
    // onOpen();
    setModal(true);
  };

  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  console.log(items);

  return (
    <Flex justifyContent="center" alignItems="center" flexDirection="column">
      {categoryName === "Turkish Coffee" ? (
        <VStack
          fontFamily="Comfortaa, Arial"
          mt="8px"
          gap="4px"
          alignItems="start"
          width="100%"
        >
          <Box
            w="100%"
            mb="8px"
            sx={{
              "@media (min-width: 200px) and (max-width: 299px)": {
                height: "150px",
              },
              "@media (min-width: 300px) and (max-width: 349px)": {
                height: "170px",
              },
              "@media (min-width: 450) and (max-width: 499px)": {
                height: "280px",
              },
              "@media (min-width: 500px) and (max-width: 559px)": {
                height: "300px",
              },
              "@media (min-width: 560px)": {
                height: "326px",
              },
            }}
          >
            <Image
              boxShadow="0px 2px 3px 3px rgba(0, 0, 0, 0.10)"
              // maxH="326px"
              // h="326px"
              // maxH="326px"
              // h="270px"
              // h="100%"

              sx={{
                height: "100%",
                objectFit: "cover",
              }}
              w="100%"
              borderRadius="28px"
              src={tCoffeImg}
              onClick={() => handleTCoffee(tCoffeImg)}
              cursor="pointer"
            />
          </Box>
          {items?.map((item, index) => (
            <>
              <Flex mt="8px" alignItems="center">
                <Box
                  // textTransform="capitalize"

                  fontSize="18px"
                  fontWeight="600"
                >
                  {t(`name.${item.name}`)}
                </Box>
              </Flex>

              <Text color={"#f7906c"} fontSize="22px">
                {t(`cost`)}: {item.price} ₼
              </Text>
              {index < items?.length - 1 && (
                <Divider
                  mb="12px"
                  borderColor={"#f7906c61"}
                  borderWidth="1px"
                />
              )}
            </>
          ))}
        </VStack>
      ) : (
        items?.map((item, index) => (
          <VStack
            fontFamily="Comfortaa, Arial"
            mt="8px"
            key={item.id}
            gap="4px"
            alignItems="start"
            width="100%"
          >
            {item?.photoPath && (
              <Box
                w="100%"
                sx={{
                  "@media (min-width: 200px) and (max-width: 299px)": {
                    height: "150px",
                  },
                  "@media (min-width: 300px) and (max-width: 349px)": {
                    height: "170px",
                  },
                  "@media (min-width: 450) and (max-width: 499px)": {
                    height: "280px",
                  },
                  "@media (min-width: 500px) and (max-width: 559px)": {
                    height: "300px",
                  },
                  "@media (min-width: 560px)": {
                    height: "326px",
                  },
                }}
              >
                <Image
                  boxShadow="0px 2px 3px 3px rgba(0, 0, 0, 0.10)"
                  // maxH="326px"
                  // h="326px"
                  // maxH="326px"
                  // h="270px"
                  // h="100%"

                  sx={
                    [
                      "Iced Coffee",
                      "Lemonade",
                      "Milkshake",
                      "Mocktails",
                    ].includes(item.categoryName)
                      ? {
                          "@media (min-width: 200px) and (max-width: 300px)": {
                            height: "150px",
                          },
                          "@media (min-width: 300px) and (max-width: 349px)": {
                            height: "170px",
                          },
                          "@media (min-width: 349px) and (max-width: 450px)": {
                            height: "210px",
                          },
                          "@media (min-width: 450px) and (max-width: 500px)": {
                            height: "280px",
                          },
                          "@media (min-width: 500px) and (max-width: 560px)": {
                            height: "300px",
                          },
                          "@media (min-width: 560px)": {
                            height: "326px",
                          },
                          objectFit: "scale-down",
                        }
                      : {
                          height: "100%",
                          objectFit: "cover",
                        }
                  }
                  w="100%"
                  borderRadius="28px"
                  src={`https://api.menu.siciliano.az/Images/${item?.photoPath}`}
                  // alt={item.name}
                  onClick={() => handleImageClick(item)}
                  cursor="pointer"
                />
              </Box>
            )}
            <HStack mt="8px" alignItems="center">
              <Text
                // textTransform="capitalize"

                fontSize="18px"
                fontWeight="600"
              >
                {/* {t(`name.${item.name}`)} */}
                {/* {item.name} */}
                {/* {categoryName === "Fresh Fruit Juices" ||
              categoryName === "Fruit Juices" ||
              categoryName === "Lemonade" ||
              categoryName === "Milkshake" ||
              categoryName === "Fruit Juices" ||
              categoryName === "Turkish Coffee" ||
              categoryName === "Desserts"
                ? t(`name.${item.name}`)
                : item.name} */}
                {[
                  "Fresh Fruit Juices",
                  "Fruit Juices",
                  "Lemonade",
                  "Milkshake",
                  "Desserts",
                ].includes(categoryName)
                  ? t(`name.${item.name}`)
                  : item.name}
              </Text>
              {item?.isNew && (
                <Text
                  ml="12px"
                  padding="4px 12px"
                  // pb="0px"
                  borderRadius="12px"
                  bg="red"
                  color="white"
                >
                  {t("new")}
                </Text>
              )}
            </HStack>
            {item.descriptionAz && (
              <Text
                textTransform="capitalize"
                lineHeight="22px"
                fontWeight="500"
                fontSize="14px"
                color="#716b68"
                letterSpacing="0.4px"
              >
                {/* {item.description} */}
                {/* {language === "az" ? item.descriptionAz : item.descriptionRu} */}
                {t(`description.${item.descriptionAz}`)}
              </Text>
            )}
            <Text color={"#f7906c"} fontSize="24px">
              {t(`cost`)}: {item.price} ₼
            </Text>
            {index < items?.length - 1 && (
              <Divider mb="12px" borderColor={"#f7906c61"} borderWidth="1px" />
            )}
          </VStack>
        ))
      )}

      {modal && selectedItem && (
        <div className="modal">
          <div
            onClick={toggleModal}
            style={{ color: "red ! important" }}
            className="overlay"
          ></div>
          <div className="modal-content">
            {categoryName === "Turkish Coffee" ? (
              <>
                <Image
                  src={tCoffeImg}
                  w="100%"
                  h={"auto"}
                  objectFit="default"
                />
                <Button
                  // style={{
                  //   // top: "20px",
                  //   // right: "30px",
                  //   // padding: "5px 7px",
                  //   height: "30px",
                  //   width: "30px",
                  //   background: "gray",
                  // }}

                  position="absolute"
                  top="2%"
                  right="7%"
                  padding="5px 7px"
                  height="30px"
                  width="30px"
                  border-radius="50%"
                  transition=".4s"
                  // bg="gray"
                  // color="red"
                  // colorScheme="wheet"
                  // variant="solid"
                  // bgColor={"#gray"}
                  border="2px dashed gray"
                  borderStyle="ridge"

                  bgColor={"#e0e0e0"}
                  _hover={{ backgroundColor: "white" }}
                  onClick={toggleModal}
                >
                  <FontAwesomeIcon
                    fontSize="20px"
                    color="black"
                    icon={faXmark}
                  />
                </Button>
              </>
            ) : (
              <>
                <Image
                  src={`https://api.menu.siciliano.az/Images/${selectedItem?.photoPath}`}
                  alt={selectedItem?.name}
                  w="100%"
                  h={
                    selectedItem?.categoryName === "Iced Coffee" ||
                    selectedItem?.categoryName === "Lemonade" ||
                    selectedItem?.categoryName === "Milkshake" ||
                    selectedItem?.categoryName === "Mocktails"
                      ? "360px"
                      : "auto"
                  }
                  // h="420px"
                  objectFit={
                    selectedItem?.categoryName === "Lemonade"
                      ? "scale-down"
                      : ["Iced Coffee", "Milkshake", "Mocktails"].includes(
                          selectedItem?.categoryName
                        )
                      ? "contain"
                      : "default"
                  }
                />
                <Text
                  fontWeight="600"
                  color="#F3F3F3"
                  // pl="8px"
                  p="12px 0 4px 8px"
                  // pt="12px"
                  fontSize="16px"
                  bg="#3a3a3a"
                >
                  {selectedItem?.name}
                </Text>

                <button className="close-modal" onClick={toggleModal}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </Flex>
  );
};

export default CategoryItems;
