// src/components/QRCodeGenerator.js
import React from 'react';
import QRCode from 'qrcode.react';
import { Box, Center } from '@chakra-ui/react';

const QRCodeGenerator = ({ url }) => {
  return (
    <Box p="4" display="flex" justifyContent="center" alignItems="center">
      <QRCode value={url} size={256} />
    </Box>
  );
};

export default QRCodeGenerator;
